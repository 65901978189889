<!--  -->
<template>
  <div class="white-bg po20" style="height: 100vh">
    <div class="text-center font-bold text-theme mb20" style="font-size: 18px">帮助文档</div>
    <el-row :gutter="40" style="height: calc(100vh - 80px)" v-loading="loading">
      <el-col :span="5" class="h100 overflow-auto">
        <el-form @submit.native.prevent>
          <el-form-item>
            <el-input v-model="documentName" placeholder="请输入文档名称" clearable>
              <el-button slot="append" icon="el-icon-search" @click="init"></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <el-tree draggable node-key="id" :data="curDocList" :props="defaultProps" :defaultProps="defaultProps" default-expand-all highlight-current @node-click="handleNodeClick">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="mr10">
              <i v-if="data.type == 1" class="el-icon-menu" />
              <i v-if="data.type == 2" class="el-icon-files" />
              <span v-html="data.documentName"></span>
            </span>
          </span>
        </el-tree>
      </el-col>
      <el-col :span="19" class="h100 overflow-auto" v-loading="infoLoading">
        <mavon-editor class="h100" style="box-shadow: none" v-model="docDetail" :subfield="false" :ishljs="true" :navigation="true" :toolbars="{ navigation: true }" defaultOpen="preview" :toolbarsFlag="true" :editable="false" :scrollStyle="true"></mavon-editor>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Api from '@/api'
export default {
  data() {
    return {
      loading: false,
      docTreeList: [],
      curDocList: [],
      curSystemCode: '',
      documentName: '',
      defaultProps: {
        children: 'chirldren',
        label: 'documentName',
      },
      curTreeNode: {},
      docDetail: '',
      infoLoading: false,
    }
  },
  created() {
    this.curSystemCode = this.$route.query.code
    this.init()
  },
  methods: {
    async init() {
      try {
        this.loading = true
        let { data } = this.documentName ? await Api.getDocumentName({ documentName: this.documentName, code: this.curSystemCode }) : await Api.getDirectoryTree({ code: this.curSystemCode })
        this.curDocList = data || []
      } finally {
        this.loading = false
      }
    },
    async handleNodeClick(row) {
      this.curTreeNode = { ...row }
      if (row.type == 2) {
        try {
          this.infoLoading = true
          const { data } = await Api.getDirectoryDetail(row.docId)
          this.docDetail = ''
          this.$nextTick(() => {
            this.docDetail = data?.content
          })
        } finally {
          this.infoLoading = false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
